import { Component, inject, input } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { AuthService, Customer, DialogsService } from '@fc-core';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromAdminStore from '../../../store';
import { currentUserHasLegacyPermission } from '../../../store';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { environment } from '../../../../environments/environment';
import { UserCustomerSelectComponent } from '../../../dialogs/customer-chooser-dialog/user-customer-select.component';

@Component({
  selector: 'fc-nav-footer-accordion',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatRippleModule,
    RouterLink,
    IconComponent,
  ],
  templateUrl: './nav-footer-accordion.component.html',
  styleUrls: ['./nav-footer-accordion.component.scss'],
})
export class NavFooterAccordionComponent {
  isOpen = input<boolean>();
  authService = inject(AuthService);
  dialogsService: DialogsService = inject(DialogsService);
  store = inject(Store);

  currentCustomer$: Observable<Customer> = this.store.select(
    fromAdminStore.getCurrentCustomer,
  );

  get getEnvironmentName(): string {
    return environment.environment;
  }

  openCustomerChooserDialog() {
    this.dialogsService.openFullScreenDialog(UserCustomerSelectComponent);
  }

  refreshPage(): void {
    window.location.reload();
  }
}
