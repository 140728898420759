import { Component } from '@angular/core';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { MatDialogClose } from '@angular/material/dialog';
import { getVersion } from '@fc-shared/utils/data.utils';
import { CopyToClipDirective } from '@fc-shared/directives/copy-to-clip.directive';

declare let zE: any;

@Component({
  selector: 'fc-help-center-dialog',
  standalone: true,
  imports: [
    IconButtonComponent,
    IconComponent,
    MatDialogClose,
    CopyToClipDirective,
  ],
  template: `
    <div class="header align-center justify-between">
      <div class="h2">Help center</div>
      <fc-icon-button [mat-dialog-close]="true">
        <fc-icon icon="icon-clear"></fc-icon>
      </fc-icon-button>
    </div>

    <div class="subtitle text-sm text-body">
      If you have any questions about the work, please contact us or leave your
      feedback.
    </div>

    <div class="help-list">
      <a
        href="tel:+19804997991"
        class="help-list-item flex-row justify-between"
      >
        <div class="help-label">
          <div class="label-icon">
            <fc-icon icon="icon-call" color="primary600"></fc-icon>
          </div>
          <span>Call us</span>
        </div>
        <div class="text-secondary">+1 (980) 499-7991</div>
      </a>

      <a
        href="mailto:support@fleetchaser.com"
        class="help-list-item flex-row justify-between"
      >
        <div class="help-label">
          <div class="label-icon">
            <fc-icon icon="icon-mail_outline" color="primary600"></fc-icon>
          </div>
          <span>Write us</span>
        </div>
        <div class="text-secondary">support&#64;fleetchaser.com</div>
      </a>

      <div
        (click)="openChat()"
        class=" help-list-item flex-row justify-between"
      >
        <div class="help-label">
          <div class="label-icon">
            <fc-icon
              icon="icon-chat_bubble_outline"
              color="primary600"
            ></fc-icon>
          </div>

          Live chat
        </div>
      </div>
      <a
        href="https://fc.fyi/Jbp5N"
        target="_blank"
        class=" help-list-item flex-row justify-between"
      >
        <div class="help-label">
          <div class="label-icon success">
            <fc-icon icon="icon-send" color="success500"></fc-icon>
          </div>

          <span>Send feedback</span>
        </div>
      </a>
    </div>
  `,
  styles: `
    @use 'var' as *;

    :host {
      padding: 12px 16px;
    }

    :host,
    .help-list {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .help-list-item {
      cursor: pointer;
      padding: 8px 12px;
      border: 1px solid transparent;
      align-items: center;
    }

    .help-list-item:hover {
      border-radius: 6px;
      border: 1px solid $secondary-100;
      background: $secondary-50;
    }

    .help-label {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .label-icon {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid $primary-600;
      background: $primary-100;

      &.success {
        background: $success-100;
        border-color: $success-500;
      }
    }
  `,
})
export class HelpCenterDialogComponent {
  get version(): string {
    return getVersion();
  }

  openChat(): void {
    try {
      if (zE) zE('messenger', 'open');
    } catch (error) {
      console.log('Error opening chat', error);
    }
  }
}
