import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CustomerSelectComponent } from './customer-select.component';
import { AuthService } from '../../auth/auth.service';
import { AsyncPipe } from '@angular/common';
import { AuthCustomer } from '../../auth/models/auth-customer';
import { map } from 'rxjs/operators';
import { FirebaseService } from '@fc-core/services/firebase.service';
import { Router } from '@angular/router';
import { User } from '@fc-core';

@Component({
  selector: 'fc-user-customer-select',
  standalone: true,
  imports: [CustomerSelectComponent, AsyncPipe],
  template: `
    <fc-customer-select
      [customers]="customers$ | async"
      (selectCustomer)="switchCustomer($event)"
    ></fc-customer-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCustomerSelectComponent {
  auth = inject(AuthService);
  router = inject(Router);
  firebaseService = inject(FirebaseService);
  customers$ = this.auth.getCustomers(this.auth.token);

  switchCustomer(customer: AuthCustomer) {
    this.auth
      .selectCustomer(customer.customerId)
      .pipe(map((user: User) => this.onSuccessfulCustomerSwitch(user)))
      .subscribe();
  }

  onSuccessfulCustomerSwitch(user: User) {
    if (user == null) return;
    this.firebaseService.init();
    this.router.navigate(['/map']);
    window.location.reload();
  }
}
