import { Component, input } from '@angular/core';
import { IconButtonComponent } from '@fc-shared/ui/buttons/icon-button.component';
import { IconComponent } from '@fc-shared/ui/icon/icon.component';
import { MatDialogClose, MatDialogTitle } from '@angular/material/dialog';

@Component({
  selector: 'fc-dialog-header',
  standalone: true,
  imports: [IconButtonComponent, IconComponent, MatDialogClose, MatDialogTitle],
  template: `
    <div class="header">
      <h2 mat-dialog-title>{{ title() }}</h2>
      <div class="close">
        <fc-icon-button mat-dialog-close>
          <fc-icon icon="icon-clear"></fc-icon>
        </fc-icon-button>
      </div>
    </div>
  `,
})
export class DialogHeaderComponent {
  title = input.required<string>();
}
