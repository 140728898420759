import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CustomerSelectComponent } from './customer-select.component';
import { AuthService, Customer, CustomerApiService } from '@fc-core';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'fc-dev-customer-select',
  standalone: true,
  imports: [CustomerSelectComponent, AsyncPipe],
  template: `
    <fc-customer-select
      [customers]="customers$ | async"
      (selectCustomer)="switchCustomer($event)"
    ></fc-customer-select>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevCustomerSelectComponent {
  auth = inject(AuthService);
  customerApi = inject(CustomerApiService);
  customers$ = this.customerApi.loadAll();

  switchCustomer(customer: Customer) {
    this.auth.switchToCustomer(customer.id).subscribe();
  }
}
